import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import variables from "../GlobalVariables"
import Title from "../UI/Title"

const WholeThing = styled.div`
  background: ${variables.greyColor};
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  margin: 10px auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 10px;
  transition: ease 0.2s;

  a {
    color: ${variables.clearColor};
  }

  &:hover {
    box-shadow: 0px 0px 34px 8px rgba(0, 0, 0, 0.42);
    transition: ease 0.2s;
    transform: scale(1.01);
  }

  @media screen and (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media screen and (max-width: ${variables.smallScreen}) {
    flex-direction: column;
    width: 90%;
  }
`

const Top = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }

  @media screen and (max-width: ${variables.mediumScreen}) {
    width: 50%;
    img {
      max-width: 350px;
    }
  }

  @media screen and (max-width: ${variables.smallScreen}) {
    width: 100%;
    img {
      max-width: 350px;
    }
  }
`

const Bottom = styled.div`
  background: ${variables.greyColor};
  padding: 10px;
  margin-left: 20px;

  &:hover {
    background: ${variables.greyColor};
  }
`

const PostTag = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  p {
    border: ${variables.accentColor} 1px solid;
    color: ${variables.accentColor};
    padding: 5px 15px;
    margin: 3px;
    border-radius: 50px;
    overflow: hidden;
  }

  p:hover {
    background: ${variables.accentColor};
    color: ${variables.clearColor};
    transition: 300ms;
  }
`

const LatestPost = props => {
  const postData = props.data

  const blogPostTags = postData.tags.map(postTag => (
    <p key={postTag.id}>#{postTag.name}</p>
  ))

  return (
    <WholeThing>
      <Top>
        <img
          src={postData.feature_image}
          alt="Daniel Jimenez Web Developer"
        ></img>
      </Top>
      <Link to={props.to}>
        <Bottom>
          <Title size="m">{postData.title}</Title>
          <p>Posted on: {postData.published_at}</p>
          <hr />
          <p>{postData.excerpt}</p>
          <PostTag>{blogPostTags}</PostTag>
        </Bottom>
      </Link>
    </WholeThing>
  )
}

export default LatestPost
