import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Title from "../components/UI/Title"
import styled from "styled-components"
import Container from "../components/Container/Container"
import BlogCard from "../components/BlogItems/SingleBlogCard"
import LatestPost from "../components/BlogItems/LatestPost"
import variables from "../components/GlobalVariables"

const LayoutStyleBlog = styled.div`
  margin: 8vh auto;
  min-height: 92vh;
  width: 70%;
  position: relative;

  @media (max-width: ${variables.mediumScreen}) {
    top: 80px;
    width: 95%;
  }

  @media (max-width: ${variables.smallScreen}) {
    top: 2vh;
  }
`

const OtherPosts = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
const NewPost = styled.div`
  display: flex;
  flex-direction: row;
`

const Blog = props => {
  const blogPosts = props.data.allGhostPost.edges
  const latestPost = blogPosts[0]
  const restOfPosts = blogPosts.slice(1)

  const blogEntries = restOfPosts.map(blogPost => {
    return (
      <BlogCard
        data={blogPost.node}
        key={blogPost.node.id}
        to={`/blog/${blogPost.node.slug}`}
      />
    )
  })

  return (
    <Layout>
      <Container>
        <SEO
          title="Blog"
          description="A blog where i discuss multiple thing about varioys topics from web development to casual daily things such as gaming and shows"
        />
        <LayoutStyleBlog>
          <Title size="l">Recent Post</Title>
          <br />
          <NewPost>
            <LatestPost
              data={latestPost.node}
              key={latestPost.node.id}
              to={`/blog/${latestPost.node.slug}`}
            />
          </NewPost>
          <br />
          <Title size="l">Other Posts</Title>
          <OtherPosts>{blogEntries}</OtherPosts>
        </LayoutStyleBlog>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allGhostPost(
      sort: { fields: created_at, order: DESC }
      filter: { id: { nin: "Ghost__Post__5bbafb3cb7ec4135e42fce56" } }
    ) {
      edges {
        node {
          slug
          published_at(formatString: "MMM DD YYYY")
          authors {
            location
            name
          }
          title
          excerpt
          id
          feature_image
          canonical_url
          page
          tags {
            description
            id
            name
          }
        }
        previous {
          id
          slug
          excerpt
          title
        }
        next {
          id
          slug
          excerpt
          title
        }
      }
    }
    allGhostTag {
      nodes {
        name
        id
      }
    }
  }
`

export default Blog
